import http from '@/config/http-common';
import FinanceType from '@/types/FinanceType';
import Product from '@/types/Product';
import Segment from '@/types/Segment';
import File from '@/types/File';
import DocumentType from '@/types/DocumentType';

class DocumentDataService {
  static getFiltered(segment: Segment, product: Product): Promise<any> {
    return http.get(`/documents?segment=${segment.pid}&product=${product.pid}`);
  }

  static upload(token: string|null, file: File, documentType: DocumentType, form: FormData, progress: any): Promise<any> {
    form.append('document_type', documentType.pid);

    return http.post(`/file/${file.pid}/documents/create`, form, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        progress(percentCompleted);
      },
    });
  }

  static clearInput(token: string|null, file: File, documentType: DocumentType, recaptcha: string): Promise<any> {
    return http.post(`/file/${file.pid}/documents/clear`, {
      documentType: documentType.pid,
      recaptcha,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default DocumentDataService;
