
import { defineComponent } from 'vue';
import globals from '../../helpers/globals';
import FormButton from '../../components/forms/Button.vue';
import DocumentDataService from '../../services/DocumentDataService';
import ModalData from '../../types/ModalData';
import SimulationDataHelper from '../../helpers/SimulationDataHelper';
import FileDataService from '../../services/FileDataService';

export default defineComponent({
  name: 'Ready',
  mixins: [
    globals,
  ],
  components: {
    FormButton,
  },
  created() {
    const vm = this;

    /* Try to set profile if authenticated */
    this.setProfile(this.$store);

    /* LOAD DATA WHEN COMPONENT IS CREATED */
    this.$store.dispatch('isLoading', true);

    this.load((data: any) => {
      vm.$store.dispatch('setSelectedSimulation', data);

      vm.init();
    });
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* FLAG STEP AS UNCOMPLETED */
      this.setStepAsCompleted(this, false);

      /* MOVE PROGRESS BAR TO VALUE */
      this.progress(this);

      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', true);
      this.$store.dispatch('toggleSideBar', true);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    /* LOAD DATA THROUGH APIS */
    load(callback: any) {
      SimulationDataHelper.prepareComponentResult(
        this.$store,
        this.$route.params.simulation as string,
        callback,
      );
    },
    modalShow() {
      const vm = this;

      this.$store.dispatch('isLoading', true);

      DocumentDataService.getFiltered(this.$store.state.selected.segment, this.$store.state.selected.product)
        .then((response: any) => {
          vm.$store.dispatch('setDocumentTypes', response.data.data);

          vm.$store.dispatch('modal', {
            id: 'documents-available-modal',
            title: 'Important !',
            description: vm.modalDescription(),
            checkbox: {
              placeholder: 'Je confirme la disponibilité de ces documents.',
            },
            actions: {
              cancel: {
                label: 'Non, pas maintenant',
                callback: () => { /* */ },
              },
              proceed: {
                label: 'Oui, c\'est bon',
                callback: () => {
                  vm.setStepAsCompleted(this, true);

                  vm.initLocalStorage();

                  const token = this.getAccessToken();

                  FileDataService.create(token, vm.$route.params.simulation as string)
                    .then((responses: any) => {
                      vm.$store.dispatch('setSelectedFile', responses.data.data);
                    }).catch((e: Error) => {
                      this.$store.dispatch('isLoading', false);

                      vm.$router.push({
                        name: 'Register',
                        params: {
                          simulation: vm.$store.state.selected.simulation.outputs.pid,
                        },
                      });
                    });
                },
              },
            },
          } as ModalData);

          this.$store.dispatch('isLoading', false);
        })
        .catch((e: Error) => { /* */ });
    },
    modalDescription() {
      const icon = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" class="h-4 w-4 inline-block mr-2 -mt-1" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve"> <style type="text/css"> .st0{fill:#0054FF;} .st1{fill:#FFFFFF;} </style> <circle class="st0" cx="16" cy="15.9" r="15.8"></circle> <polygon class="st1" points="23,9 13.2,18.8 9,14.5 6.4,17 10.7,21.3 13.2,23.8 15.8,21.3 25.6,11.5 "></polygon> </svg>';

      const text = `
        Assurez-vous d'avoir à votre portée les documents suivants afin de les inclure dans votre demande de financement :
        <br>
      `;

      let block = '';
      Object.keys(this.$store.state.documentTypes).forEach((key: any) => {
        block = block.concat(`<div class="mt-2 font-medium text-left">${icon}${this.$store.state.documentTypes[key].name}</div>`);
      });

      return `${text}<div class="flex flex-col mx-auto mt-4 w-4/5 2xl:w-3/5">${block}</div>`;
    },
  },
});
